  
export default function validateInfo(values) {
    let errors = {};
  
    if (!values.name.trim()) {
      errors.name = 'Va rugam introduceti un nume.';
    }
    // else if (!/^[A-Za-z]+/.test(values.name.trim())) {
    //   errors.name = 'Enter a valid name';
    // }
  
    if (!values.phoneNr) {
      errors.phoneNr = 'Va rugam sa introduceti un numar de contact.';
    } 

    if (!values.persoane) {
      errors.persoane = 'Va rugam sa specificati cate persoane ve-ti fi.';
    }
  
    if (!values.date) {
      errors.date = 'Va rugam sa specificati data rezervarii.';
    }

    return errors;
  }
import React from 'react';
import validate from './validateInfo';
import useForm from './useForm';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import './Form.css';

const FormSignup = ({ submitForm }) => {
  const { handleChange, handleSubmit, values, errors } = useForm(
    submitForm,
    validate
  );

  return (
    <div className='form-content-right'>
        <h2>Fa o rezervare acum</h2>
      <form onSubmit={handleSubmit} className='form' noValidate>
        <div className='form-inputs'>
          <input
            className='form-input'
            type='text'
            name='name'
            placeholder='Nume *'
            value={values.name}
            onChange={handleChange}
          />
          {errors.name && <p className="error_message">{errors.name}</p>}
        </div>
        <div className='form-inputs'>
          <input
            className='form-input'
            type='number'
            name='phoneNr'
            placeholder='Nr. Telefon *'
            value={values.phoneNr}
            onChange={handleChange}
          />
          {errors.phoneNr && <p className="error_message">{errors.phoneNr}</p>}
        </div>
        <div className='form-inputs'>
          <input
            className='form-input'
            type='number'
            name='persoane'
            placeholder='Nr. Persoane *'
            value={values.persoane}
            onChange={handleChange}
          />
          {errors.persoane && <p className="error_message">{errors.persoane}</p>}
        </div>
        <div className='form-inputs'>
          <input
            className='form-input'
            type='datetime-local'
            name='date'
            placeholder='Data si Ora rezervarii *'
            value={values.date}
            onChange={handleChange}
          />
          {errors.date && <p className="error_message">{errors.date}</p>}
        <div className='form-inputs'>
          <input
            className='form-input'
            type='text'
            name='other'
            placeholder='Alte mentiuni'
          />
        </div>
        <div>
            <p></p>
        </div>
        </div>
            <Button
                variant="contained"
                color="primary"
                className='send__btn'
                endIcon={<SendIcon/>}
                type="submit"
                value="Send"
            >
                Trimite
            </Button>
      </form>
    </div>
  );
};

export default FormSignup;
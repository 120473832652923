import React from 'react';
import Auxiliary from '../../../hoc/Auxiliary';
import Backdrop from '../../UI/Backdrop/Backdrop';
import ToscaLogo from '../../../assets/images/Tosca logo.jfif';
import './Modal.css';
import ReactDom from 'react-dom';

export default function Modal({open, children, onClose}) {
    if(!open) return null;

    return ReactDom.createPortal(
        <Auxiliary>
        <Backdrop backdropClickHandler={onClose}/>

        <div className="Modal">
            <img src={ToscaLogo} alt="Logo"/>
            {/* <button onClick={onClose}>Close Modal</button> */}
            {children}
        </div>
        </Auxiliary>,
        document.getElementById('portal')
    )
}

import React from 'react';
import './termeni_conditii.css';

function termeni_conditii() {
    return (
        <div className="blog_type">
            <h1> Termeni si conditii</h1>
            <br/>

            <h3> Despre noi</h3>
            <p> Acest site (https://tosca1991.ro) este proprietatea S.C. Tosca S.R.L în continuare denumit și „Tosca 1991”. </p>
            <p> Date de identificare companie: </p>
            <ul>
                <li> Cod Unic de Înregistrare RO2040737 </li>
                <li> Nr. înmatriculare J27/190/1991 </li>
                <li> Adresa: Blv. Traian nr. 17, Bloc A4, Parter, 610130 </li>
            </ul>
            <p> Contact: </p>
            <ul>
                <li> 0771 049 546 </li>
                <li> office.tosca1991@gmail.com </li>
            </ul>
            <br/>

            <h3> Condiții de utilizare site </h3>
            <p> Prin accesarea site-ului sunteți de acord cu termenii și condițiile menționate pe această pagină și cu informațiile de pe pagina Protecția datelor.</p>
            <p> Tosca S.R.L, în calitate de autor/proprietar/administrator al website-ului https://tosca1991.ro/ își rezervă dreptul de a schimba și actualiza în orice moment conținutul acestui website, precum și Politica de confidențialitate și Termenii și condițiile de folosire, fără niciun fel de notificare prealabilă. Datorită acestui fapt, vă rugăm să vizitați periodic această secțiune pentru a verifica termenii și condițiile pe care ați agreat să le respectați. </p>
            <br/>

            <h3>Link-urile</h3>
            <p>Acest site conține link-uri către alte site-uri. Tosca S.R.L nu este responsabil de politica de confidențialitate practicată de aceștia. Vă recomandăm consultarea în prealabil a termenilor legali și a celorlalte informații referitoare la colectarea datelor cu caracter personal.</p>
            <br/>

            <h3>Drepturi de autor</h3>
            <p> Tosca S.R.L (Tosca 1991) este autorul și proprietarul site-ului și beneficiază de toate drepturile legale recunoscute autorilor, conform legislației române și internaționale. În lipsa altor precizări, toate materialele, incluzând imagini, ilustrații, design, icoane, fotografii, clipuri audio/video, s.a.m.d sunt proprietatea Tosca 1991 și nu pot fi folosite fără notificarea și acordul proprietarului. </p>
            <br/>
            
        </div>
    )
}

export default termeni_conditii

import React from 'react';
import './App.css';
import Layout from './hoc/Layout/Layout';
import {Switch, Route, Redirect} from 'react-router-dom';
import Home from './component/PageContents/Home/Home';
import Galerie from './component/PageContents/Galerie/Galerie';
import HappyHour from './component/PageContents/Happy Hour/HappyHour.js';
import TermeniConditii from './component/PageContents/Misc/termeni_conditii.js';
import ProtectiaDatelor from './component/PageContents/Misc/protectia_datelor.js';

const routes = (
  <Switch>
    <Route path="/happyhour" component={HappyHour} />
    <Route path="/galerie" component={Galerie} />
    <Route path="/home" exact component={Home} />
    <Route path="/termeni-conditii" exact component={TermeniConditii} />
    <Route path="/protectia-datelor" exact component={ProtectiaDatelor} />
    <Route path="/" exact component={Home} />
    <Redirect to="/" />
  </Switch>
)

function App() {
  return (
      <Layout>
        {routes}
      </Layout>
  );
}

export default App;

import React from 'react';
import './Form.css';

const FormSuccess = () => {
  return (
    <div className='form-content-right'>
      <h2 className='form-success'>Rezervarea a fost facuta. Va multumim !</h2>
    </div>
  );
};

export default FormSuccess;

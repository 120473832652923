import img1 from '../../../assets/images/Gallery/Poza Gallery 1.jpg';
import img2 from '../../../assets/images/Gallery/Poza Gallery 2.jpg';
import img3 from '../../../assets/images/Gallery/Poza Gallery 3.jpg';
import img4 from '../../../assets/images/Gallery/Poza Gallery 4.jpg';
import img5 from '../../../assets/images/Gallery/Poza Gallery 5.jpg';
import img6 from '../../../assets/images/Gallery/Poza Gallery 6.jpg';
import img7 from '../../../assets/images/Gallery/Poza Gallery 7.jpg';
import img8 from '../../../assets/images/Gallery/Poza Gallery 8.jpg';
import img9 from '../../../assets/images/Gallery/Poza Gallery 9.jpg';
import img10 from '../../../assets/images/Gallery/Poza Gallery 10.jpg';
import img11 from '../../../assets/images/Gallery/Poza Gallery 11.jpg';
import img12 from '../../../assets/images/Gallery/Poza Gallery 12.jpg';
import img13 from '../../../assets/images/Gallery/Poza Gallery 13.jpg';
import img14 from '../../../assets/images/Gallery/Poza Gallery 14.jpg';
import img15 from '../../../assets/images/Gallery/Poza Gallery 15.jpg';
import img16 from '../../../assets/images/Gallery/Poza Gallery 16.jpg';
import img17 from '../../../assets/images/Gallery/Poza Gallery 17.jpg';
import img18 from '../../../assets/images/Gallery/Poza Gallery 18.jpg';
import img19 from '../../../assets/images/Gallery/Poza Gallery 19.jpg';
import img20 from '../../../assets/images/Gallery/Poza Gallery 20.jpg';
import img21 from '../../../assets/images/Gallery/Poza Gallery 21.jpg';
import img22 from '../../../assets/images/Gallery/Poza Gallery 22.jpg';
import img23 from '../../../assets/images/Gallery/Poza Gallery 23.jpg';
import img24 from '../../../assets/images/Gallery/Poza Gallery 24.jpg';
import img25 from '../../../assets/images/Gallery/Poza Gallery 25.jpg';
import img26 from '../../../assets/images/Gallery/Poza Gallery 26.jpg';



export const photos = [
    {
      src: img1,
      width: 7,
      height: 8
    },
    {
      src: img2,
      width: 7,
      height: 6
    },
    {
      src: img3,
      width: 3,
      height: 4 
    },
    {
      src: img4,
      width: 3,
      height: 2
    },
    {
      src: img5,
      width: 3,
      height: 2
    },
    {
      src: img6,
      width: 3,
      height: 4
    },
    {
      src: img7,
      width: 4,
      height: 4
    },
    {
      src: img8,
      width: 3,
      height: 2
    },
    {
      src: img9,
      width: 3,
      height: 2
    },
    {
      src: img10,
      width: 4,
      height: 3
    },
    {
      src: img11,
      width: 3,
      height: 2
    },
    {
      src: img12,
      width: 3,
      height: 2
    },
    {
      src: img13,
      width: 3,
      height: 2
    },
    {
      src: img14,
      width: 3,
      height: 2
    },
    {
      src: img15,
      width: 2,
      height: 3
    },
    {
      src: img16,
      width: 2,
      height: 3
    },
    {
      src: img17,
      width: 3,
      height: 2
    },
    {
      src: img18,
      width: 3,
      height: 2
    },
    {
      src: img19,
      width: 3,
      height: 2
    },
    {
      src: img20,
      width: 3,
      height: 2
    },
    {
      src: img21,
      width: 3,
      height: 2
    },
    {
      src: img22,
      width: 3,
      height: 2
    },
    {
      src: img23,
      width: 4,
      height: 3
    },
    {
      src: img24,
      width: 3,
      height: 2
    },
    {
      src: img25,
      width: 2,
      height: 3
    },
    {
      src: img26,
      width: 2,
      height: 3
    },
  ];
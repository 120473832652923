import React from 'react';
import './termeni_conditii.css';

function protectia_datelor() {
    return (
        <div className="blog_type">
            <h1> Protectia Datelor </h1>
            <br/>

            <h3> Despre noi </h3>
            <p> S.C. Tosca S.R.L. (în continuare denumită ca „Tosca 1991”) este operator de date cu caracter personal și își exercită această calitate în conformitate cu Regulamentul (UE) 679/2016 al Parlamentului European și al Consiliului din 27 aprilie 2016 privind protecția persoanelor fizice în ceea ce privește prelucrarea datelor cu caracter personal si privind libera circulație a acestor date și de abrogare a Directivei 95/46/CE (Regulamentul general privind protecția datelor- RGPD) și a legii 190/2018 privind măsuri de punere în aplicare a RGPD. </p>
            <p> Date de identificare companie: </p>
            <ul>
                <li> Cod Unic de Înregistrare RO2040737 </li>
                <li> Nr. înmatriculare J27/190/1991 </li>
                <li> Adresa: Blv. Traian nr. 17, Bloc A4, Parter, 610130 </li>
            </ul>   

            <p> Contact: </p>
            <ul>
                <li> 0771 049 546 </li>
                <li> office.tosca1991@gmail.com </li>
            </ul>
            <br/>

            <h3> Prelucrarea datelor </h3>
            <p> Prelucrăm date personale în următoarele situații: </p>
            <ul>
                <li> Rezervare masă în locație: nume, număr de telefon, email </li>
                <li> Livrare comandă: nume, număr de telefon, email, adresă </li>
            </ul>
            <p> Aceste date nu vor fi folosite strict pentru scopurile menționate, nu vor fi păstrate mai mult de o lună și nu vor fi divulgate către terțe părți (cu excepția livratorului, dacă este cazul). </p>
            <br/>

            <h3> Politica de utilizare cookie-uri </h3>
            <p> Utilizăm cookie-uri doar pentru măsurarea traficului pe site (Analytics). Prin continuarea utilizării acestui site sunteți de acord în legătură cu folosirea acestor cookie-uri. </p>
            <p> Mai multe detalii despre Regulamentul general privind protecția datelor aici: https://eur-lex.europa.eu/legal-content/RO/TXT/?uri=CELEX%3A32016R0679 </p>

        </div>
    )
}

export default protectia_datelor

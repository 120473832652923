import React from 'react';
import './HappyHour.css';
import happyHour from '../../../assets/images/happy_hour.jpg';

export default function HappyHour() {
    return (
        <div className="Happy__Hour">
            <h2>Oferta Saptamanii</h2>
            <img src={happyHour} alt="oferta" />
        </div>
    )
}

import React from 'react';
/* import ToscaLogo from '../../../../assets/images/Tosca logo.jfif'; */
import './SideDrawer.css';

const sideDrawer = props => {
    let drawerClasses = 'side-drawer';
    if (props.show) {
        drawerClasses = 'side-drawer open';
    }

    return( 
     <nav className={drawerClasses}>
{/*         <div className="Logo">
             <img src={ToscaLogo} alt="Logo"/>
        </div> */}
         <ul>
            <li><a href="/">HOME</a></li>
            <li><a href="/happyhour">HAPPY HOUR</a></li>
            <li><a href="https://tosca1991.ro/meniu-tosca1991.pdf/view" 
                rel='noopener noreferrer'
                target="_blank">MENU</a>
            </li>
            <li><a href="/galerie">GALERIE</a></li>
         </ul>
     </nav>
    )
}

export default sideDrawer;
import React from 'react';
import "./Footer.css";
import ToscaSVG from '../../../assets/images/logo tosca 1991.svg';
import TripAdvisorIcon from '../../../assets/images/tripadvisor_logo.png';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

const Footer = (props) => {
    return (
        <div className="footer">
            <div className="Linkuri_utile">
                <p className="Bold"> Linkuri Utile </p>
                <p> <a href="https://anpc.ro/" target="_blank" rel="noopener noreferrer" > ANPC </a> </p>
                <p> <a href="/termeni-conditii"> Termeni si Conditii </a> </p>
                <p> <a href="/protectia-datelor"> Protectia Datelor </a> </p>
                <br/>
                <p className="Italic"> Website made by <a href="https://www.twitch.tv/count_bear" target="_blank" rel="noopener noreferrer">Urs</a>. </p>
            </div>
            <div className="ToscaLogo">
                <object data={ToscaSVG} width="150" height="150"> </object>
            </div>
            <div className="Social_media_links">
                    <p className="Bold"> Follow us at: </p>
                    <div className="Social_media_logos">
                        <a href="https://www.instagram.com/tosca1991/?hl=en">
                            <InstagramIcon fontSize="large"/>
                        </a>
                        <a href="https://www.tripadvisor.com/Restaurant_Review-g304062-d21351042-Reviews-Tosca_1991-Piatra_Neamt_Neamt_County_Northeast_Romania.html">
                            <img className="tripadvisor" src={TripAdvisorIcon} alt="tripadvisor"/>
                        </a>
                        <a href="https://www.facebook.com/tosca1991">
                            <FacebookIcon fontSize="large"/>
                        </a>
                    </div>
                </div>
        </div>
    )
}

export default Footer;


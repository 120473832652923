import React, {useState} from 'react';
import {VeryShortText} from '../../../assets/Others/Placeholder_text';
import ReactPlayer from "react-player"
import Map from './Map';
import Auxiliary from '../../../hoc/Auxiliary';
//import {Element} from 'react-scroll';
import { FacebookProvider, Page } from 'react-facebook';
import Modal from '../../UI/Modal/Modal';
import Form from '../../Form/Form';

import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import RoomIcon from '@material-ui/icons/Room';
import TripAdvisorIcon from '../../../assets/images/tripadvisor_logo.png';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import './Home.css';

import FoodImg from '../../../assets/images/meniu mancare tosca 1991.jpg';
import introPic from "../../../assets/images/imagine fundal intro.jpg";
import desertPic from "../../../assets/images/fundal desert tosca 1991.jpg";
import winePic from "../../../assets/images/imagine vinuri tosca 1991.jpg";
import aboutPic from "../../../assets/images/fundal sectiune about.jpg";

const location = {
    address: 'Tosca 1991 - Bistro Cafe',
    lat: 46.9246,
    lng: 26.378176,
  }


const Home = props => {

    const[isModalOpen, setIsModalOpen] = useState(false);

    return(
        <Auxiliary>

        <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
            <Form/>
        </Modal>

        <div className="Home__page" >
            <div className="Cafe__section">
                <div className="Cafe__section__Wrapper">
                    <img className="introImg" src={introPic} alt="intro pic"/> 
                    <div className = "Cafe__section__text">
                        <h1>BINE AI VENIT</h1>
                        <p> Acesta este locul in care poti savura o cafea de specialitate, un smoothy natural din fructe atent alese, un ceai de calitate, un mic dejun deosebit, un pranz sau o cina pentru cele mai rafinate gusturi, un desert exclusivist, un cocktail preparat cu grija si, nu in ultimul rand, o bautura de calitate.</p>
                    </div>
                </div>
            </div>

            <div className="Food__section">
                <img className = "FoodPic" src={FoodImg} alt="food img" />
                <div className = "Food__section__text">
                    <h1>Food</h1> 
                    <p className="B-O-L-D"> Nimic nu e lasat la voia intamplarii. </p>
                    <p> Fiecare preparat in parte este gandit special pentru un paring perfect cu colectia de bauturi, astfel incat experienta ta sa fie una completa!</p>
                    <p className="Italic"> "Fa ca MANCAREA sa-ti fie medicamentul si medicamentul mancarea." HIPOCRATES </p>
                    <button
                        type="button"
                        onClick={(e) => {
                                e.preventDefault();
                                window.open('https://tosca1991.ro/meniu-tosca1991.pdf');
                                }}
                    > Vezi meniul complet </button>
                    <p>  </p>
                    <button
                        type="button"
                        onClick={(e) => {
                                e.preventDefault();
                                window.open('https://we-shop.ro/vendors/vendor/view/code/tosca/');
                                }}
                    > Comanda online de pe WeShop </button>
                </div>
                
            </div>

            <div className="Cafe__section">
                <div className="Cafe__section__Wrapper">
                    <img className  ="introImg" src={desertPic} alt="desert pic"/> 
                    <div className = "Cakes__section__text">
                        <h1>SWEETS</h1>
                        <p className="B-O-L-D"> Iti dam motivul sa zambesti </p>
                        <p> Un desert pentru orice stare sau pentru orice pofta. Create si preparate cu iscusinta in laboratorul propriu, deserturile noastre aduc un final fericit oricarei mese.</p>
                        <p className="Italic"> "Viata e prea scurta ca sa nu comanzi si desert" GRADY BOOCH </p>
                    </div>
                </div>
            </div>

            <div className="Food__section">
                <img src={winePic} alt="wine img" />
                <div className = "Food__section__text">
                    <h1>WINE</h1>   
                    <p className="B-O-L-D"> Armonia perfecta intre gust si buchet. </p>
                    <p> O colectie de vinuri special gandita si construita pentru un food & wine paring exceptional. Mai pe scurt, vinul la noi este cel care pune punctul pe I. </p>
                    <p className="Italic"> "Vinul bucura sufletul omului si bucuria este mama tuturor virtutilor" JOHANN WOLFGANG VON GOETHE </p>
                </div>
            </div>

            <div className="Cafe__section" style={{marginBottom:"0px"}}>
                <div className="Cafe__section__Wrapper">
                    <img className="introImg" src={aboutPic} alt="about pic"/> 
                    <div className = "Cafe__section__text">
                        <h1>ABOUT US</h1>
                        <p> Situat in Piatra Neamt, Bistro Tosca 1991 ne surprinde cu caldura si comfortul unui bistro elegant, si cu un meniu international bogat. La Tosca in fiecare zi te bucuri de o adevarata experienta senzoriala de exceptie. </p>
                        <div className='player-wrapper'>
                            <ReactPlayer
                            className='react-player'
                            url='https://www.youtube.com/watch?v=m8RzfuTD64Q&feature=youtu.be'
                            width='100%'
                             height='100%'
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="Unde__section">
                <div className = "Unde__section__text">
                    <h1>UNDE...</h1>   
                    <p className="B-O-L-D">...ne gasesti </p>
                    <br/>
                    <div className="Icon_and_text" >
                        <RoomIcon fontSize="large" style={{ color: "rgb(255, 241, 214)"}}/>
                        <span> Blv. Traian , Nr.17 , Bloc A4 </span>
                    </div>
                    <div className="Icon_and_text" >
                        <PhoneIcon fontSize="large" style={{ color: "rgb(255, 241, 214)"}}/>
                        <span> 0771 049 546 / 0744 599 511 </span>
                    </div>
                    <div className="Icon_and_text" >
                        <MailIcon fontSize="large" style={{ color: "rgb(255, 241, 214)"}}/>
                        <span> office.tosca1991@gmail.com </span>
                    </div>
                    <br/>
                    <button onClick={() => setIsModalOpen(true)}> Rezerva online cu 24 ore inainte</button> 
                </div>
                <div className="socialMediaEmbed">
                    <div className="facebook_page">
                    <FacebookProvider appId={process.env.REACT_APP_FACEBOOK_API_KEY}>
                        <Page href="https://www.facebook.com/tosca1991/" tabs="timeline" />
                    </FacebookProvider>  
                    </div>
                </div>
                <div className="Google_map">
                    <Map location={location} zoomLevel={17}/>
                </div>
            </div>

        </div>

        </Auxiliary>
    )
}

export default Home;